import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy-redirect-astoria',
  template: '',
})
export class BuyRedirectAstoriaComponent {
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(['/buy'], { queryParams: { partner: 'astoria', type: 'FREE' } });
    }
  }
}
