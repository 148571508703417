import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { appRoutes } from '@helpers/core/app-routes.helpers';
import { BuyRedirectComponent } from './buy-redirect-component/buy-redirect-component.component';
import { BuyRedirectSfComponent } from './buy-redirect-sf/buy-redirect-sf.component';
import { BuyRedirectComponentGerardComponent } from './buy-redirect-component-gerard/buy-redirect-component-gerard.component';
import { BuyRedirectComponentCunyComponent } from './buy-redirect-component-cuny/buy-redirect-component-cuny.component';
import { BuyRedirectHolidayComponent } from './buy-redirect-holiday/buy-redirect-holiday.component';

const routes: Routes = [
  {
    path: appRoutes.empty,
    redirectTo: appRoutes.search,
    pathMatch: 'full',
  },
  {
    path: appRoutes.home,
    redirectTo: appRoutes.search,
    pathMatch: 'full',
  },
  {
    path: appRoutes.januusCommunity,
    loadChildren: () =>
      import('./januus-community/januus-community.module').then((m) => m.JanuusCommunityModule),
  },
  {
    path: appRoutes.januusOfficial,
    loadChildren: () =>
      import('./januus-official/januus-official.module').then((m) => m.JanuusOfficialModule),
  },
  {
    path: appRoutes.virtualAgenda,
    loadChildren: () =>
      import('./manejo-agenda/manejo-ajenda.module').then((m) => m.ManejoAjendaModule),
  },
  {
    path: appRoutes.products,
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: appRoutes.buy,
    loadChildren: () => import('./buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: 'freecard',
    component: BuyRedirectComponent 
  },
  {
    path: 'buy-sf',
    component: BuyRedirectSfComponent // Usa el nuevo componente para redirección
  },
  {
  path: 'buy-gerard',
  component: BuyRedirectComponentGerardComponent // Usa el nuevo componente para redirección
  },
  {
    path: 'buy-nyc',
    component: BuyRedirectComponentGerardComponent // Usa el nuevo componente para redirección
  },
  {
    path: 'buy-cuny',
    component: BuyRedirectComponentCunyComponent // Usa el nuevo componente para redirección
  },
  {
    path: 'holidays-shopping',
    component: BuyRedirectHolidayComponent // Usa el nuevo componente para redirección
  },
  {
    path: appRoutes.search,
    loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: appRoutes.about,
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: appRoutes.brand,
    loadChildren: () => import('./brand/brand.module').then((m) => m.BrandModule),
  },
  {
    path: appRoutes.embajadoresDeMarca,
    loadChildren: () => import('./brand/brand.module').then((m) => m.BrandModule),
  },
  {
    path: appRoutes.joinUs,
    loadChildren: () => import('./joinus/joinus.module').then((m) => m.JoinusModule),
  },
  {
    path: appRoutes.trabajaConNosotros,
    loadChildren: () => import('./joinus/joinus.module').then((m) => m.JoinusModule),
  },
  {
    path: appRoutes.compras,
    loadChildren: () => import('./compras/compras.module').then((m) => m.ComprasModule),
  },
  {
    path: appRoutes.purchase,
    loadChildren: () => import('./compras/compras.module').then((m) => m.ComprasModule),
  },
  {
    path: appRoutes.learnMore,
    loadChildren: () => import('./learn-more/learn-more.module').then((m) => m.LearnMoreModule),
  },
  {
    path: appRoutes.page,
    loadChildren: () =>
      import('./landing-pages/landing-pages.module').then((m) => m.LandingPagesModule),
  },
  {
    path: appRoutes.plans,
    loadChildren: () => import('./plans/plans.module').then((m) => m.PlansModule),
  },
  {
    path: appRoutes.login,
    loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: appRoutes.register,
    loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: appRoutes.registerOptions,
    loadChildren: () =>
      import('./auth/register-options/register-options.module').then(
        (m) => m.RegisterOptionsModule,
      ),
  },
  {
    path: appRoutes.loginOptions,
    loadChildren: () =>
      import('./auth/login-options/login-options.module').then((m) => m.LoginOptionsModule),
  },
  {
    path: appRoutes.forgot,
    loadChildren: () => import('./auth/forgot/forgot.module').then((m) => m.ForgotModule),
  },
  {
    path: appRoutes.activate,
    loadChildren: () => import('./activate/activate.module').then((m) => m.ActivateModule),
  },
  {
    path: appRoutes.my,
    loadChildren: () => import('./my-profile/my-profile.module').then((m) => m.MyProfileModule),
    canActivate: [AuthGuard],
  },
  // TODO: Revisar si esta ruta se seguira usando
  {
    path: appRoutes.payments,
    loadChildren: () => import('./payments/payments.module').then((m) => m.PaymentsModule),
  },
  // TODO: Revisar si esta ruta se seguira usando
  {
    path: appRoutes.reservas,
    loadChildren: () => import('./reservas/reservas.module').then((m) => m.ReservasModule),
    canActivate: [AuthGuard],
  },
  {
    path: appRoutes.bookings,
    loadChildren: () => import('./reservas/reservas.module').then((m) => m.ReservasModule),
    canActivate: [AuthGuard],
  },
  // TODO: Revisar si aun se seguira utilizando
  {
    path: appRoutes.image,
    loadChildren: () => import('./image/image.module').then((m) => m.ImageModule),
  },
  {
    path: appRoutes.success,
    loadChildren: () =>
      import('./about/components/pro-assurance/pro-assurance.module').then(
        (m) => m.ProAssuranceModule,
      ),
  },
  {
    path: appRoutes.rankingAndRating,
    loadChildren: () =>
      import('./about/components/ranking-and-rating/ranking-and-rating.module').then(
        (m) => m.RankingAndRatingModule,
      ),
  },
  {
    path: appRoutes.workWithUs,
    loadChildren: () =>
      import('./work-with-us/work-with-us.module').then((m) => m.WorkWithUsModule),
  },
  {
    path: appRoutes.notFound,
    redirectTo: appRoutes.search,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
