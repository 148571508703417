import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/**
 * Enviroment
 */
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadjsonService } from '@services/loadjson.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslocoRootModule } from './core/internatiolization/transloco-root.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { HttpSsrInterceptor } from './core/http-ssr-interceptor.service';
import { BuyRedirectComponent } from './buy-redirect-component/buy-redirect-component.component';
import { BuyRedirectSfComponent } from './buy-redirect-sf/buy-redirect-sf.component';
import { BuyRedirectComponentGerardComponent } from './buy-redirect-component-gerard/buy-redirect-component-gerard.component';
import { BuyRedirectComponentCunyComponent } from './buy-redirect-component-cuny/buy-redirect-component-cuny.component';
import { BuyRedirectHolidayComponent } from './buy-redirect-holiday/buy-redirect-holiday.component';
import { BuyRedirectZoeComponent } from './buy-redirect-zoe/buy-redirect-zoe.component';
import { BuyRedirectArbelComponent } from './buy-redirect-arbel/buy-redirect-arbel.component';
import { RegisterRedirectBrooklynComponent } from './register-redirect-brooklyn/register-redirect-brooklyn.component';
import { BuyRedirectAstoriaComponent } from './buy-redirect-astoria/buy-redirect-astoria.component';

@NgModule({
  declarations: [AppComponent, BuyRedirectComponent, BuyRedirectSfComponent, BuyRedirectComponentGerardComponent, BuyRedirectComponentCunyComponent, BuyRedirectHolidayComponent, BuyRedirectZoeComponent, BuyRedirectArbelComponent, RegisterRedirectBrooklynComponent, BuyRedirectAstoriaComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslocoRootModule
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  providers: [
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSsrInterceptor,
      multi: true,
    },*/
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    LoadjsonService,
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
